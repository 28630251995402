import i18nEnUsRawlingsCommon from '../../platform/i18n/en-US/en-US._rawlings.i18next-v4.json' with { type: 'json' }

const DEFAULT_MENU = 'filter.category'
const DEFAULT_MENU_WHEN_SKU = null
const SKU_MATCHES = ['calc.sku']
const SKU_OMITS = []
const I18N_RESOURCES = {
  'en-US': {
    _rawlings: i18nEnUsRawlingsCommon,
  },
}

// TODO: Add types.
export {
  DEFAULT_MENU,
  DEFAULT_MENU_WHEN_SKU,
  SKU_MATCHES,
  SKU_OMITS,
  I18N_RESOURCES,
}
