// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorRow } from '../typings';

const data = [
  {
    "id": "AO",
    "name": "Athletic Orange",
    "props": {
      "hex": "#a02000",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "volt": "#a02000"
      }
    }
  },
  {
    "id": "B",
    "name": "Black",
    "props": {
      "hex": "#343536",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#343536",
        "clear": "#151515",
        "metallic": "#242424",
        "volt": "#242424"
      }
    }
  },
  {
    "id": "BO",
    "name": "Burnt Orange",
    "props": {
      "hex": "#dd4f1b",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#dd4f1b"
      }
    }
  },
  {
    "id": "BR",
    "name": "Brown",
    "props": {
      "hex": "#452a00",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#452a00"
      }
    }
  },
  {
    "id": "BRK",
    "name": "Brick",
    "props": {
      "hex": "#721220",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#721220"
      }
    }
  },
  {
    "id": "C",
    "name": "Cardinal",
    "props": {
      "hex": "#6a3c42",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#6a3c42",
        "clear": "#5d2029",
        "metallic": "#5a1e27",
        "volt": "#af0013"
      }
    }
  },
  {
    "id": "CB",
    "name": "Columbia Blue",
    "props": {
      "hex": "#7faad1",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#7faad1",
        "clear": "#6fa3cd",
        "metallic": "#6ba1c9"
      }
    }
  },
  {
    "id": "CR",
    "name": "Cubs Royal",
    "props": {
      "hex": "#002c68",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#002c68"
      }
    }
  },
  {
    "id": "DG",
    "name": "Dark Green",
    "props": {
      "hex": "#39453f",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#39453f",
        "clear": "#1f3f37",
        "metallic": "#1f3f37",
        "volt": "#13362e"
      }
    }
  },
  {
    "id": "GD",
    "name": "Gold",
    "props": {
      "hex": "#958257",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "volt": "#958257"
      }
    }
  },
  {
    "id": "GR",
    "name": "Graphite",
    "props": {
      "hex": "#5e636d",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#5e636d",
        "clear": "#4f5560",
        "metallic": "#474c53"
      }
    }
  },
  {
    "id": "KG",
    "name": "Kelly Green",
    "props": {
      "hex": "#5b9b45",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#5b9b45",
        "clear": "#5b9b45",
        "metallic": "#5b9b45",
        "volt": "#5b9b45"
      }
    }
  },
  {
    "id": "LG",
    "name": "Light Gold",
    "props": {
      "hex": "#ffa000",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#ffa000",
        "clear": "#ffa000",
        "metallic": "#ffa000"
      }
    }
  },
  {
    "id": "M",
    "name": "Maroon",
    "props": {
      "hex": "#45383a",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#45383a",
        "clear": "#341a1f",
        "metallic": "#2f1a1d",
        "volt": "#56001a"
      }
    }
  },
  {
    "id": "N",
    "name": "Navy",
    "props": {
      "hex": "#343945",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#343945",
        "clear": "#192844",
        "metallic": "#283648",
        "volt": "#192844"
      }
    }
  },
  {
    "id": "NB",
    "name": "New Blue",
    "props": {
      "hex": "#151935",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#151935"
      }
    }
  },
  {
    "id": "NCB",
    "name": "Neon Columbia Blue",
    "props": {
      "hex": "#009fb4",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#009fb4",
        "clear": "#009fb4"
      }
    }
  },
  {
    "id": "NG",
    "name": "Neon Green",
    "props": {
      "hex": "#81d037",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#81d037",
        "clear": "#81d037"
      }
    }
  },
  {
    "id": "NO",
    "name": "Neon Orange",
    "props": {
      "hex": "#ff6538",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#ff6538",
        "clear": "#ff6538"
      }
    }
  },
  {
    "id": "NPK",
    "name": "Neon Pink",
    "props": {
      "hex": "#ff5379",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#ff5379",
        "clear": "#ff5379"
      }
    }
  },
  {
    "id": "NPU",
    "name": "Neon Purple",
    "props": {
      "hex": "#861e81",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#861e81",
        "clear": "#861e81"
      }
    }
  },
  {
    "id": "O",
    "name": "Orange",
    "props": {
      "hex": "#ee6041",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#ee6041",
        "clear": "#ee4f1a",
        "metallic": "#ba4824"
      }
    }
  },
  {
    "id": "OP",
    "name": "Optic Pink",
    "props": {
      "hex": "#c6588f",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#c6588f",
        "clear": "#c04383"
      }
    }
  },
  {
    "id": "OPK",
    "name": "Optic Pink",
    "props": {
      "hex": "#f75782",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "metallic": "#f75782"
      }
    }
  },
  {
    "id": "OY",
    "name": "Optic Yellow",
    "props": {
      "hex": "#ffff00",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#ffff00",
        "clear": "#ffff00",
        "metallic": "#ffff00"
      }
    }
  },
  {
    "id": "PK",
    "name": "Pink",
    "props": {
      "hex": "#a94974",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "metallic": "#a94974"
      }
    }
  },
  {
    "id": "PU",
    "name": "Purple",
    "props": {
      "hex": "#443d61",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#443d61",
        "clear": "#241c3d",
        "metallic": "#2d2448",
        "volt": "#2d2448"
      }
    }
  },
  {
    "id": "R",
    "name": "Royal",
    "props": {
      "hex": "#324a7c",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#324a7c",
        "clear": "#003779",
        "metallic": "#123e6e",
        "volt": "#00315f"
      }
    }
  },
  {
    "id": "RS",
    "name": "Ranger Scarlet",
    "props": {
      "hex": "#911427",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#911427"
      }
    }
  },
  {
    "id": "S",
    "name": "Scarlet",
    "props": {
      "hex": "#b53a41",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#b53a41",
        "clear": "#960711",
        "metallic": "#ae011e",
        "volt": "#af0013"
      }
    }
  },
  {
    "id": "SL",
    "name": "Silver",
    "props": {
      "hex": "#a9acab",
      "techLogoColorId": "B",
      "hexByFinishId": {
        "matte": "#a9acab",
        "metallic": "#a9acab"
      }
    }
  },
  {
    "id": "SR",
    "name": "Seattle Royal",
    "props": {
      "hex": "#0b1639",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "clear": "#0b1639"
      }
    }
  },
  {
    "id": "TX",
    "name": "Texas Orange",
    "props": {
      "hex": "#a26649",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#a26649",
        "clear": "#b65627",
        "metallic": "#b45829"
      }
    }
  },
  {
    "id": "VG",
    "name": "Vegas Gold",
    "props": {
      "hex": "#bba679",
      "techLogoColorId": "W",
      "hexByFinishId": {
        "matte": "#bba679",
        "metallic": "#b39e72"
      }
    }
  },
  {
    "id": "W",
    "name": "White",
    "props": {
      "hex": "#f8f6f6",
      "techLogoColorId": "B",
      "hexByFinishId": {
        "matte": "#f8f6f6",
        "clear": "#f5f7f4",
        "metallic": "#f1f4f3",
        "volt": "#f1f4f3"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorRow[]>> = data;

export type Color = typeof typed[number];

export const COLOR_INDEX_KEY = "id";
export type ColorIndexKey = "id";
export type ColorId = Color["id"];

let i = 0;
export const COLOR_DICT = {
  "AO": typed[i++],
  "B": typed[i++],
  "BO": typed[i++],
  "BR": typed[i++],
  "BRK": typed[i++],
  "C": typed[i++],
  "CB": typed[i++],
  "CR": typed[i++],
  "DG": typed[i++],
  "GD": typed[i++],
  "GR": typed[i++],
  "KG": typed[i++],
  "LG": typed[i++],
  "M": typed[i++],
  "N": typed[i++],
  "NB": typed[i++],
  "NCB": typed[i++],
  "NG": typed[i++],
  "NO": typed[i++],
  "NPK": typed[i++],
  "NPU": typed[i++],
  "O": typed[i++],
  "OP": typed[i++],
  "OPK": typed[i++],
  "OY": typed[i++],
  "PK": typed[i++],
  "PU": typed[i++],
  "R": typed[i++],
  "RS": typed[i++],
  "S": typed[i++],
  "SL": typed[i++],
  "SR": typed[i++],
  "TX": typed[i++],
  "VG": typed[i++],
  "W": typed[i++]
} as const;

export { typed as COLORS };
