const defaultViewAngleId = 'overview'

const viewAngles = {
  overview: {
    label: 'Overview',
    exposeForUI: true,
  },
  front: {
    exposeForUI: true,
    exposeForRecipePreviewImages: true,
    previewSize: {
      width: 1200, // duplicate value in Finalize.css ".finalize-preview img"
      height: 630,
    },
  },
}

export default viewAngles
export { defaultViewAngleId }
