import { useState, useCallback } from 'react'

const useCanvas = () => {
  // Use state to kick re-render after canvas is created
  const [canvas, setCanvas] = useState(null)
  const ref = useCallback((node) => {
    if (node !== null) {
      setCanvas(node)
    }
  }, [])
  return [canvas, ref]
}

const useCounter = () => {
  // Use state to kick re-render after refresh is requested
  const [counter, updateCounter] = useState(0)
  const increment = () => {
    updateCounter((counter) => counter + 1)
  }
  return [counter, increment]
}

export { useCanvas, useCounter }
