import * as _ from '@technically/lodash'
import '@babylonjs/loaders'

import getAsset from '~p/getAsset'

import { COLOR_DEF_DICT, FACEGUARD_MODEL_DICT } from '../common/sheets'

export const Settings = {
  MAX_TEXTURE_SIZE: 512,
}

const getHex = (areaDef) => {
  const colorDef = _.find(
    COLOR_DEF_DICT,
    (colorDef) =>
      colorDef.props.colorId === areaDef.color.id &&
      colorDef.props.finishId === areaDef.finish.id,
  )
  if (!colorDef) {
    throw new Error(
      `No colorDef found: ${areaDef.color.id}, ${areaDef.finish.id}`,
    )
  }
  return colorDef.props.hex
}

const getHexOptional = (areaDef) => {
  if (!areaDef) {
    return undefined
  }
  return getHex(areaDef)
}

const getClearCoatHex = (areaDef) => areaDef.finish.props.clearCoatHex

const getClearCoatHexOptional = (areaDef) => {
  if (!areaDef) {
    return undefined
  }
  return getClearCoatHex(areaDef)
}

const getTextureSvg = (basePath, areaDef) =>
  getAsset(`${basePath}${areaDef.finish.id}.svg`)

const getTextureSvgOptional = (basePath, areaDef) => {
  if (!areaDef) {
    return undefined
  }
  return getTextureSvg(basePath, areaDef)
}

const faceguardAssetVisibilityMap = _.transform(
  FACEGUARD_MODEL_DICT,
  (result, model) => {
    result[model.props.assetId] = false
  },
)

const getTechLogoColor = (helmet, prefix) => {
  const areaId =
    _.find(
      helmet.style.props.techLogoBg[helmet.model.id],
      (value, key) => !!value && _.startsWith(key, prefix),
    ) || 'area1'
  return helmet[areaId].color.props.techLogoColorId === 'W' ?
      '#F8F6F6'
    : '#343536'
}

export const getRenderData = (data, cameraDef, width, height) => {
  if (!data.helmet || !data.helmet.model) {
    return null
  }
  const helmet = data.helmet
  const faceguard = data.faceguard.model ? data.faceguard : undefined

  const assetIds = helmet.model.props.assetIds
  const { paddingSuffix, frontPaddingSuffix } = assetIds
  const techLogoVisibility = _.mapValues(
    helmet.style.props.techLogoBg[helmet.model.id],
    _.identity,
  )

  const result = {
    cameraDef,
    width,
    height,
    environmentTexture: getAsset(`lightmap.env`),
    modelGlb: getAsset(`helmets/${assetIds.base}/${assetIds.base}.glb`),
    meshVisibilityData: {
      carbonInserts: helmet.model.id === 'MachCarbon',
      carbonWeaveMask:
        helmet.model.id === 'MachCarbon' &&
        (helmet.style.id === 'velo' || helmet.style.id === 'stripes'),
      faceguards: {
        ...faceguardAssetVisibilityMap,
        [faceguard ? faceguard.model.props.assetId : 'faceguardNONE']: true,
      },
      screws: !!faceguard && faceguard.model.props.hasScrews,
      flapSide:
        faceguard && faceguard.flapSide && faceguard.flapSide.props.assetId,
      paddingSuffix,
      frontPaddingSuffix,
      techLogoVisibility,
    },

    textures: {
      outside: {
        albedo: {
          svg: getAsset(
            `helmets/${assetIds.base}/designs/${helmet.style.id}.svg`,
          ),
          colors: {
            area1: getHex(helmet.area1),
            area2: getHexOptional(helmet.area2),
            area3: getHexOptional(helmet.area3),
          },
        },
        clearCoat: {
          svg: getAsset(
            `helmets/${assetIds.base}/designs/${helmet.style.id}.svg`,
          ),
          colors: {
            area1: getClearCoatHex(helmet.area1),
            area2: getClearCoatHexOptional(helmet.area2),
            area3: getClearCoatHexOptional(helmet.area3),
          },
        },
        clearCoatNormal: getAsset(
          `helmets/${assetIds.base}/clearCoatNormal2048.png`,
        ),
        metallic: {
          svg: getAsset(
            `helmets/${assetIds.base}/designs/${helmet.style.id}.svg`,
          ),
          textureSvgs: {
            area1: getTextureSvg(
              `helmets/${assetIds.base}/materials/materials_`,
              helmet.area1,
            ),
            area2: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/materials_`,
              helmet.area2,
            ),
            area3: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/materials_`,
              helmet.area3,
            ),
          },
        },
        normal: {
          svg: getAsset(
            `helmets/${assetIds.base}/designs/${helmet.style.id}.svg`,
          ),
          textureSvgs: {
            area1: getTextureSvg(
              `helmets/${assetIds.base}/materials/normal_`,
              helmet.area1,
            ),
            area2: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/normal_`,
              helmet.area2,
            ),
            area3: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/normal_`,
              helmet.area3,
            ),
          },
        },
      },
      inside: {
        albedo: {
          svg: getAsset(`helmets/${assetIds.base}/inside.svg`),
          colors: {
            area1: getHex(helmet.area1),
          },
        },
        metallic: {
          textureSvg: getTextureSvg(
            `helmets/${assetIds.base}/materials/materials_`,
            helmet.area1,
          ),
        },
      },
      padding: {
        albedo: {
          svg: getAsset(
            `helmets/${assetIds.base}/padding${
              assetIds.paddingSuffix || ''
            }.svg`,
          ),
        },
      },
      techLogoSide: {
        albedo: {
          svg: getAsset(`helmets/techLogo.svg`),
          colors: {
            area1: getTechLogoColor(helmet, 'side'),
          },
        },
      },
      techLogoBack: {
        albedo: {
          svg: getAsset(`helmets/techLogo.svg`),
          colors: {
            area1: getTechLogoColor(helmet, 'back'),
          },
        },
      },
      faceguard: faceguard && {
        albedo: {
          svg: getAsset(
            `helmets/${assetIds.base}/${faceguard.model.props.assetId}.svg`,
          ),
          colors: {
            area1: getHex(faceguard.area1),
            area2: getHexOptional(faceguard.area2),
          },
        },
        clearCoat: {
          svg: getAsset(
            `helmets/${assetIds.base}/${faceguard.model.props.assetId}.svg`,
          ),
          colors: {
            area1: getClearCoatHex(faceguard.area1),
            area2: getClearCoatHexOptional(faceguard.area2),
          },
        },
        metallic: {
          svg: getAsset(
            `helmets/${assetIds.base}/${faceguard.model.props.assetId}.svg`,
          ),
          textureSvgs: {
            area1: getTextureSvg(
              `helmets/${assetIds.base}/materials/materials_`,
              faceguard.area1,
            ),
            area2: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/materials_`,
              faceguard.area2,
            ),
          },
        },
        normal: {
          svg: getAsset(
            `helmets/${assetIds.base}/${faceguard.model.props.assetId}.svg`,
          ),
          textureSvgs: {
            area1: getTextureSvg(
              `helmets/${assetIds.base}/materials/normal_`,
              faceguard.area1,
            ),
            area2: getTextureSvgOptional(
              `helmets/${assetIds.base}/materials/normal_`,
              faceguard.area2,
            ),
          },
        },
      },
    },
  }

  return result
}
