import { Matrix } from '@babylonjs/core'

const updateProjectionMatrix = (camera, engine, cameraDef) => {
  const { x = 0, y = 0 } = cameraDef.translateProjection ?? {}

  const projectionMatrix = camera.getProjectionMatrix(true)
  projectionMatrix.multiplyToRef(
    Matrix.Translation(
      (x ?? 0) / engine.getRenderWidth(),
      (y ?? 0) / engine.getRenderHeight(),
      0,
    ),
    projectionMatrix,
  )
}

export default updateProjectionMatrix
