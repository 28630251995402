// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FaceguardModelRow } from '../typings';

const data = [
  {
    "id": "MACHWG",
    "name": "MACH Wireguard",
    "props": {
      "assetId": "MACHWG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "EXT-Flap",
    "name": "MACH EXT Faceguard",
    "props": {
      "assetId": "EXT-Flap",
      "areaCount": 2,
      "price": 35,
      "hasSide": true,
      "hasScrews": false
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "ADJEXT-Flap",
    "name": "ADJ EXT Faceguard",
    "props": {
      "assetId": "ADJEXT-Flap",
      "areaCount": 2,
      "price": 35,
      "hasSide": true,
      "hasScrews": false
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "VIZSBFG",
    "name": "Hi Viz Softball Mask",
    "props": {
      "assetId": "VIZSBFG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": false,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": false,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "R16WG",
    "name": "R16WG",
    "props": {
      "assetId": "R16WG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": false,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "R16JWG",
    "name": "R16JWG",
    "props": {
      "assetId": "R16JWG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": false,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "R16SBWG",
    "name": "R16SBWG",
    "props": {
      "assetId": "R16SBWG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": false,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "R16JSBWG",
    "name": "R16JSBWG",
    "props": {
      "assetId": "R16JSBWG",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": false,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "BB1WG",
    "name": "BB1WG",
    "props": {
      "assetId": "BB1WG",
      "areaCount": 1,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "SB1WG",
    "name": "SB1WG",
    "props": {
      "assetId": "SB1WG",
      "areaCount": 1,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true,
        "small": true,
        "medium": true,
        "large": true,
        "xLarge": true,
        "oneSize": true
      },
      "modelId": {
        "R16": false,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FaceguardModelRow[]>> = data;

export type FaceguardModel = typeof typed[number];

export const FACEGUARD_MODEL_INDEX_KEY = "id";
export type FaceguardModelIndexKey = "id";
export type FaceguardModelId = FaceguardModel["id"];

let i = 0;
export const FACEGUARD_MODEL_DICT = {
  "MACHWG": typed[i++],
  "EXT-Flap": typed[i++],
  "ADJEXT-Flap": typed[i++],
  "VIZSBFG": typed[i++],
  "R16WG": typed[i++],
  "R16JWG": typed[i++],
  "R16SBWG": typed[i++],
  "R16JSBWG": typed[i++],
  "BB1WG": typed[i++],
  "SB1WG": typed[i++]
} as const;

export { typed as FACEGUARD_MODELS };
