import fp from 'lodash/fp.js'
import { concat } from 'redux-fp'

import {
  createViewNameUpdater,
  focusModeUpdater,
} from '~rawlings/client/updaters'

import controlTree from './controlTree'
import { defaultViewAngleId } from '../viewAngles'

const initialState = {
  render3d: { activeView: defaultViewAngleId },
  isFocusMode: false,
}

const getDefaultState = () => (state) => fp.assign(initialState, state)

export default concat(
  getDefaultState,
  controlTree.updater,
  createViewNameUpdater('render3d.activeView'),
  focusModeUpdater,
)
