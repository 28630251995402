// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { StyleRow } from '../typings';

const data = [
  {
    "id": "solid",
    "name": "Solid",
    "props": {
      "accentCount": 0,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": true
      }
    }
  },
  {
    "id": "mohawk",
    "name": "Mohawk",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "ace",
    "name": "Ace",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "ice",
    "name": "Ice",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideUpper": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideAlt": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideAlt": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideAlt": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "lightning",
    "name": "Lightning",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area2"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "velo",
    "name": "Velo",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideFront": "area2",
          "back": "area2"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area2"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "duckBill",
    "name": "Duck Bill",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area2"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "throwback",
    "name": "Throwback",
    "props": {
      "accentCount": 2,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": true
      }
    }
  },
  {
    "id": "fade",
    "name": "Fade",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area2"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": false
      }
    }
  },
  {
    "id": "stripes",
    "name": "Stripes",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideFront": "area2",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "vents",
    "name": "Vents",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "frontBill",
    "name": "Front Bill",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": true
      }
    }
  },
  {
    "id": "frontPanel",
    "name": "Front Panel",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": true,
        "Mach": false,
        "MachAlpha": false,
        "MachCarbon": false,
        "RCFH": true
      }
    }
  },
  {
    "id": "x2",
    "name": "X2",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "venom",
    "name": "Venom",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "retro",
    "name": "Retro",
    "props": {
      "accentCount": 2,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area2",
          "backUpper": "area2"
        },
        "MachAlpha": {
          "sideDefault": "area2",
          "backUpper": "area2"
        },
        "MachCarbon": {
          "sideDefault": "area2",
          "backUpper": "area2"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  },
  {
    "id": "2-tone",
    "name": "2-Tone",
    "props": {
      "accentCount": 1,
      "techLogoBg": {
        "R16": {
          "sideDefault": "area1",
          "back": "area1"
        },
        "Mach": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachAlpha": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "MachCarbon": {
          "sideDefault": "area1",
          "backUpper": "area1"
        },
        "RCFH": {
          "side": "area1",
          "back": "area1"
        }
      }
    },
    "subset": {
      "modelId": {
        "R16": false,
        "Mach": true,
        "MachAlpha": true,
        "MachCarbon": true,
        "RCFH": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<StyleRow[]>> = data;

export type Style = typeof typed[number];

export const STYLE_INDEX_KEY = "id";
export type StyleIndexKey = "id";
export type StyleId = Style["id"];

let i = 0;
export const STYLE_DICT = {
  "solid": typed[i++],
  "mohawk": typed[i++],
  "ace": typed[i++],
  "ice": typed[i++],
  "lightning": typed[i++],
  "velo": typed[i++],
  "duckBill": typed[i++],
  "throwback": typed[i++],
  "fade": typed[i++],
  "stripes": typed[i++],
  "vents": typed[i++],
  "frontBill": typed[i++],
  "frontPanel": typed[i++],
  "x2": typed[i++],
  "venom": typed[i++],
  "retro": typed[i++],
  "2-tone": typed[i++]
} as const;

export { typed as STYLES };
