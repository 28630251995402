// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorDefRow } from '../typings';

const data = [
  {
    "props": {
      "colorId": "B",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#343536"
    },
    "id": "matteBlack",
    "name": "Black"
  },
  {
    "props": {
      "colorId": "C",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#6a3c42"
    },
    "id": "matteCardinal",
    "name": "Cardinal"
  },
  {
    "props": {
      "colorId": "CB",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#7faad1"
    },
    "id": "matteColumbiaBlue",
    "name": "Columbia Blue"
  },
  {
    "props": {
      "colorId": "DG",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#39453f"
    },
    "id": "matteDarkGreen",
    "name": "Dark Green"
  },
  {
    "props": {
      "colorId": "GR",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#5e636d"
    },
    "id": "matteGraphite",
    "name": "Graphite"
  },
  {
    "props": {
      "colorId": "KG",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#5b9b45"
    },
    "id": "matteKellyGreen",
    "name": "Kelly Green"
  },
  {
    "props": {
      "colorId": "LG",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#ffa000"
    },
    "id": "matteLightGold",
    "name": "Light Gold"
  },
  {
    "props": {
      "colorId": "M",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#45383a"
    },
    "id": "matteMaroon",
    "name": "Maroon"
  },
  {
    "props": {
      "colorId": "N",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#343945"
    },
    "id": "matteNavy",
    "name": "Navy"
  },
  {
    "props": {
      "colorId": "O",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#ee6041"
    },
    "id": "matteOrange",
    "name": "Orange"
  },
  {
    "props": {
      "colorId": "OP",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#c6588f"
    },
    "id": "matteOpticPink",
    "name": "Optic Pink"
  },
  {
    "props": {
      "colorId": "OY",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#ffff00"
    },
    "id": "matteOpticYellow",
    "name": "Optic Yellow"
  },
  {
    "props": {
      "colorId": "PU",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#443d61"
    },
    "id": "mattePurple",
    "name": "Purple"
  },
  {
    "props": {
      "colorId": "R",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#324a7c"
    },
    "id": "matteRoyal",
    "name": "Royal"
  },
  {
    "props": {
      "colorId": "S",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#b53a41"
    },
    "id": "matteScarlet",
    "name": "Scarlet"
  },
  {
    "props": {
      "colorId": "SL",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#a9acab"
    },
    "id": "matteSilver",
    "name": "Silver"
  },
  {
    "props": {
      "colorId": "TX",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#a26649"
    },
    "id": "matteTexasOrange",
    "name": "Texas Orange"
  },
  {
    "props": {
      "colorId": "VG",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#bba679"
    },
    "id": "matteVegasGold",
    "name": "Vegas Gold"
  },
  {
    "props": {
      "colorId": "W",
      "isDisabled": false,
      "finishId": "matte",
      "hex": "#f8f6f6"
    },
    "id": "matteWhite",
    "name": "White"
  },
  {
    "props": {
      "colorId": "NG",
      "isDisabled": true,
      "finishId": "matte",
      "hex": "#81d037"
    },
    "id": "matteNeonGreen",
    "name": "Neon Green"
  },
  {
    "props": {
      "colorId": "NO",
      "isDisabled": true,
      "finishId": "matte",
      "hex": "#ff6538"
    },
    "id": "matteNeonOrange",
    "name": "Neon Orange"
  },
  {
    "props": {
      "colorId": "NPK",
      "isDisabled": true,
      "finishId": "matte",
      "hex": "#ff5379"
    },
    "id": "matteNeonPink",
    "name": "Neon Pink"
  },
  {
    "props": {
      "colorId": "NCB",
      "isDisabled": true,
      "finishId": "matte",
      "hex": "#009fb4"
    },
    "id": "matteNeonColumbiaBlue",
    "name": "Neon Columbia Blue"
  },
  {
    "props": {
      "colorId": "NPU",
      "isDisabled": true,
      "finishId": "matte",
      "hex": "#861e81"
    },
    "id": "matteNeonPurple",
    "name": "Neon Purple"
  },
  {
    "props": {
      "colorId": "BRK",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#721220"
    },
    "id": "clearBrick",
    "name": "Brick"
  },
  {
    "props": {
      "colorId": "CR",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#002c68"
    },
    "id": "clearCubsRoyal",
    "name": "Cubs Royal"
  },
  {
    "props": {
      "colorId": "NB",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#151935"
    },
    "id": "clearNewBlue",
    "name": "New Blue"
  },
  {
    "props": {
      "colorId": "BR",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#452a00"
    },
    "id": "clearBrown",
    "name": "Brown"
  },
  {
    "props": {
      "colorId": "SR",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#0b1639"
    },
    "id": "clearSeattleRoyal",
    "name": "Seattle Royal"
  },
  {
    "props": {
      "colorId": "B",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#151515"
    },
    "id": "clearBlack",
    "name": "Black"
  },
  {
    "props": {
      "colorId": "C",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#5d2029"
    },
    "id": "clearCardinal",
    "name": "Cardinal"
  },
  {
    "props": {
      "colorId": "CB",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#6fa3cd"
    },
    "id": "clearColumbiaBlue",
    "name": "Columbia Blue"
  },
  {
    "props": {
      "colorId": "DG",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#1f3f37"
    },
    "id": "clearDarkGreen",
    "name": "Dark Green"
  },
  {
    "props": {
      "colorId": "GR",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#4f5560"
    },
    "id": "clearGraphite",
    "name": "Graphite"
  },
  {
    "props": {
      "colorId": "KG",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#5b9b45"
    },
    "id": "clearKellyGreen",
    "name": "Kelly Green"
  },
  {
    "props": {
      "colorId": "LG",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#ffa000"
    },
    "id": "clearLightGold",
    "name": "Light Gold"
  },
  {
    "props": {
      "colorId": "M",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#341a1f"
    },
    "id": "clearMaroon",
    "name": "Maroon"
  },
  {
    "props": {
      "colorId": "N",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#192844"
    },
    "id": "clearNavy",
    "name": "Navy"
  },
  {
    "props": {
      "colorId": "O",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#ee4f1a"
    },
    "id": "clearOrange",
    "name": "Orange"
  },
  {
    "props": {
      "colorId": "OP",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#c04383"
    },
    "id": "clearOpticPink",
    "name": "Optic Pink"
  },
  {
    "props": {
      "colorId": "OY",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#ffff00"
    },
    "id": "clearOpticYellow",
    "name": "Optic Yellow"
  },
  {
    "props": {
      "colorId": "PU",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#241c3d"
    },
    "id": "clearPurple",
    "name": "Purple"
  },
  {
    "props": {
      "colorId": "R",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#003779"
    },
    "id": "clearRoyal",
    "name": "Royal"
  },
  {
    "props": {
      "colorId": "S",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#960711"
    },
    "id": "clearScarlet",
    "name": "Scarlet"
  },
  {
    "props": {
      "colorId": "TX",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#b65627"
    },
    "id": "clearTexasOrange",
    "name": "Texas Orange"
  },
  {
    "props": {
      "colorId": "W",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#f5f7f4"
    },
    "id": "clearWhite",
    "name": "White"
  },
  {
    "props": {
      "colorId": "NG",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#81d037"
    },
    "id": "clearNeonGreen",
    "name": "Neon Green"
  },
  {
    "props": {
      "colorId": "NO",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#ff6538"
    },
    "id": "clearNeonOrange",
    "name": "Neon Orange"
  },
  {
    "props": {
      "colorId": "NPK",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#ff5379"
    },
    "id": "clearNeonPink",
    "name": "Neon Pink"
  },
  {
    "props": {
      "colorId": "NCB",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#009fb4"
    },
    "id": "clearNeonColumbiaBlue",
    "name": "Neon Columbia Blue"
  },
  {
    "props": {
      "colorId": "NPU",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#861e81"
    },
    "id": "clearNeonPurple",
    "name": "Neon Purple"
  },
  {
    "props": {
      "colorId": "RS",
      "isDisabled": true,
      "finishId": "clear",
      "hex": "#911427"
    },
    "id": "clearRangerScarlet",
    "name": "Ranger Scarlet"
  },
  {
    "props": {
      "colorId": "BO",
      "isDisabled": false,
      "finishId": "clear",
      "hex": "#dd4f1b"
    },
    "id": "clearBurntOrange",
    "name": "Burnt Orange"
  },
  {
    "props": {
      "colorId": "B",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#242424"
    },
    "id": "metallicBlack",
    "name": "Black"
  },
  {
    "props": {
      "colorId": "C",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#5a1e27"
    },
    "id": "metallicCardinal",
    "name": "Cardinal"
  },
  {
    "props": {
      "colorId": "CB",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#6ba1c9"
    },
    "id": "metallicColumbiaBlue",
    "name": "Columbia Blue"
  },
  {
    "props": {
      "colorId": "DG",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#1f3f37"
    },
    "id": "metallicDarkGreen",
    "name": "Dark Green"
  },
  {
    "props": {
      "colorId": "GR",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#474c53"
    },
    "id": "metallicGraphite",
    "name": "Graphite"
  },
  {
    "props": {
      "colorId": "KG",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#5b9b45"
    },
    "id": "metallicKellyGreen",
    "name": "Kelly Green"
  },
  {
    "props": {
      "colorId": "LG",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#ffa000"
    },
    "id": "metallicLightGold",
    "name": "Light Gold"
  },
  {
    "props": {
      "colorId": "M",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#2f1a1d"
    },
    "id": "metallicMaroon",
    "name": "Maroon"
  },
  {
    "props": {
      "colorId": "N",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#283648"
    },
    "id": "metallicNavy",
    "name": "Navy"
  },
  {
    "props": {
      "colorId": "O",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#ba4824"
    },
    "id": "metallicOrange",
    "name": "Orange"
  },
  {
    "props": {
      "colorId": "PK",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#a94974"
    },
    "id": "metallicPink",
    "name": "Pink"
  },
  {
    "props": {
      "colorId": "OY",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#ffff00"
    },
    "id": "metallicOpticYellow",
    "name": "Optic Yellow"
  },
  {
    "props": {
      "colorId": "PU",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#2d2448"
    },
    "id": "metallicPurple",
    "name": "Purple"
  },
  {
    "props": {
      "colorId": "R",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#123e6e"
    },
    "id": "metallicRoyal",
    "name": "Royal"
  },
  {
    "props": {
      "colorId": "S",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#ae011e"
    },
    "id": "metallicScarlet",
    "name": "Scarlet"
  },
  {
    "props": {
      "colorId": "SL",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#a9acab"
    },
    "id": "metallicSilver",
    "name": "Silver"
  },
  {
    "props": {
      "colorId": "TX",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#b45829"
    },
    "id": "metallicTexasOrange",
    "name": "Texas Orange"
  },
  {
    "props": {
      "colorId": "VG",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#b39e72"
    },
    "id": "metallicVegasGold",
    "name": "Vegas Gold"
  },
  {
    "props": {
      "colorId": "W",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#f1f4f3"
    },
    "id": "metallicWhite",
    "name": "White"
  },
  {
    "props": {
      "colorId": "OPK",
      "isDisabled": false,
      "finishId": "metallic",
      "hex": "#f75782"
    },
    "id": "metallicOpticPink",
    "name": "Optic Pink"
  },
  {
    "props": {
      "colorId": "B",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#242424"
    },
    "id": "voltBlack",
    "name": "Black"
  },
  {
    "props": {
      "colorId": "C",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#af0013"
    },
    "id": "voltCardinal",
    "name": "Cardinal"
  },
  {
    "props": {
      "colorId": "DG",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#13362e"
    },
    "id": "voltDarkGreen",
    "name": "Dark Green"
  },
  {
    "props": {
      "colorId": "KG",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#5b9b45"
    },
    "id": "voltKellyGreen",
    "name": "Kelly Green"
  },
  {
    "props": {
      "colorId": "M",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#56001a"
    },
    "id": "voltMaroon",
    "name": "Maroon"
  },
  {
    "props": {
      "colorId": "N",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#192844"
    },
    "id": "voltNavy",
    "name": "Navy"
  },
  {
    "props": {
      "colorId": "PU",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#2d2448"
    },
    "id": "voltPurple",
    "name": "Purple"
  },
  {
    "props": {
      "colorId": "R",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#00315f"
    },
    "id": "voltRoyal",
    "name": "Royal"
  },
  {
    "props": {
      "colorId": "S",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#af0013"
    },
    "id": "voltScarlet",
    "name": "Scarlet"
  },
  {
    "props": {
      "colorId": "W",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#f1f4f3"
    },
    "id": "voltWhite",
    "name": "White"
  },
  {
    "props": {
      "colorId": "GD",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#958257"
    },
    "id": "voltGold",
    "name": "Gold"
  },
  {
    "props": {
      "colorId": "AO",
      "isDisabled": false,
      "finishId": "volt",
      "hex": "#a02000"
    },
    "id": "voltAthleticOrange",
    "name": "Athletic Orange"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorDefRow[]>> = data;

export type ColorDef = typeof typed[number];

export const COLOR_DEF_INDEX_KEY = "id";
export type ColorDefIndexKey = "id";
export type ColorDefId = ColorDef["id"];

let i = 0;
export const COLOR_DEF_DICT = {
  "matteBlack": typed[i++],
  "matteCardinal": typed[i++],
  "matteColumbiaBlue": typed[i++],
  "matteDarkGreen": typed[i++],
  "matteGraphite": typed[i++],
  "matteKellyGreen": typed[i++],
  "matteLightGold": typed[i++],
  "matteMaroon": typed[i++],
  "matteNavy": typed[i++],
  "matteOrange": typed[i++],
  "matteOpticPink": typed[i++],
  "matteOpticYellow": typed[i++],
  "mattePurple": typed[i++],
  "matteRoyal": typed[i++],
  "matteScarlet": typed[i++],
  "matteSilver": typed[i++],
  "matteTexasOrange": typed[i++],
  "matteVegasGold": typed[i++],
  "matteWhite": typed[i++],
  "matteNeonGreen": typed[i++],
  "matteNeonOrange": typed[i++],
  "matteNeonPink": typed[i++],
  "matteNeonColumbiaBlue": typed[i++],
  "matteNeonPurple": typed[i++],
  "clearBrick": typed[i++],
  "clearCubsRoyal": typed[i++],
  "clearNewBlue": typed[i++],
  "clearBrown": typed[i++],
  "clearSeattleRoyal": typed[i++],
  "clearBlack": typed[i++],
  "clearCardinal": typed[i++],
  "clearColumbiaBlue": typed[i++],
  "clearDarkGreen": typed[i++],
  "clearGraphite": typed[i++],
  "clearKellyGreen": typed[i++],
  "clearLightGold": typed[i++],
  "clearMaroon": typed[i++],
  "clearNavy": typed[i++],
  "clearOrange": typed[i++],
  "clearOpticPink": typed[i++],
  "clearOpticYellow": typed[i++],
  "clearPurple": typed[i++],
  "clearRoyal": typed[i++],
  "clearScarlet": typed[i++],
  "clearTexasOrange": typed[i++],
  "clearWhite": typed[i++],
  "clearNeonGreen": typed[i++],
  "clearNeonOrange": typed[i++],
  "clearNeonPink": typed[i++],
  "clearNeonColumbiaBlue": typed[i++],
  "clearNeonPurple": typed[i++],
  "clearRangerScarlet": typed[i++],
  "clearBurntOrange": typed[i++],
  "metallicBlack": typed[i++],
  "metallicCardinal": typed[i++],
  "metallicColumbiaBlue": typed[i++],
  "metallicDarkGreen": typed[i++],
  "metallicGraphite": typed[i++],
  "metallicKellyGreen": typed[i++],
  "metallicLightGold": typed[i++],
  "metallicMaroon": typed[i++],
  "metallicNavy": typed[i++],
  "metallicOrange": typed[i++],
  "metallicPink": typed[i++],
  "metallicOpticYellow": typed[i++],
  "metallicPurple": typed[i++],
  "metallicRoyal": typed[i++],
  "metallicScarlet": typed[i++],
  "metallicSilver": typed[i++],
  "metallicTexasOrange": typed[i++],
  "metallicVegasGold": typed[i++],
  "metallicWhite": typed[i++],
  "metallicOpticPink": typed[i++],
  "voltBlack": typed[i++],
  "voltCardinal": typed[i++],
  "voltDarkGreen": typed[i++],
  "voltKellyGreen": typed[i++],
  "voltMaroon": typed[i++],
  "voltNavy": typed[i++],
  "voltPurple": typed[i++],
  "voltRoyal": typed[i++],
  "voltScarlet": typed[i++],
  "voltWhite": typed[i++],
  "voltGold": typed[i++],
  "voltAthleticOrange": typed[i++]
} as const;

export { typed as COLOR_DEFS };
