// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { NavTreeItemRow } from '../typings';

const data = [
  {
    "childId": "filter",
    "name": "Filters",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "filter"
  },
  {
    "parentId": "filter",
    "childId": "productSelector",
    "name": "Helmet Finder",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": true,
    "id": "filter.productSelector"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.sport",
    "name": "Sport",
    "propId": "filter.sport",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "wizardTitle": "What <strong>sport</strong> do you play?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.sport"
  },
  {
    "parentId": "filter.productSelector",
    "childId": "productSelector.model",
    "name": "Model",
    "propId": "helmet.model",
    "isWizardStep": true,
    "isWizardStepOptional": false,
    "wizardTitle": "Which <strong>model</strong> would you like to customize?",
    "shouldShowChildren": false,
    "id": "filter.productSelector.productSelector.model"
  },
  {
    "childId": "helmet",
    "name": "Helmet",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet"
  },
  {
    "parentId": "helmet",
    "childId": "size",
    "name": "Size",
    "propId": "helmet.size",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.size"
  },
  {
    "parentId": "helmet",
    "childId": "style",
    "name": "Design Style",
    "propId": "helmet.style",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.style"
  },
  {
    "parentId": "helmet",
    "childId": "area1",
    "name": "Helmet Base",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area1"
  },
  {
    "parentId": "helmet.area1",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area1.finish",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area1.finish"
  },
  {
    "parentId": "helmet.area1",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area1.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area1.color"
  },
  {
    "parentId": "helmet",
    "childId": "area2",
    "name": "Helmet Secondary",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area2"
  },
  {
    "parentId": "helmet.area2",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area2.finish",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area2.finish"
  },
  {
    "parentId": "helmet.area2",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area2.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area2.color"
  },
  {
    "parentId": "helmet",
    "childId": "area3",
    "name": "Helmet Tertiary",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area3"
  },
  {
    "parentId": "helmet.area3",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area3.finish",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area3.finish"
  },
  {
    "parentId": "helmet.area3",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area3.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "helmet.area3.color"
  },
  {
    "childId": "faceguard",
    "name": "Faceguard",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard"
  },
  {
    "parentId": "faceguard",
    "childId": "model",
    "name": "Faceguard Model",
    "propId": "faceguard.model",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.model"
  },
  {
    "parentId": "faceguard",
    "childId": "side",
    "name": "Faceguard Side",
    "propId": "faceguard.flapSide",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.side"
  },
  {
    "parentId": "faceguard",
    "childId": "area1",
    "name": "Faceguard Base",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area1"
  },
  {
    "parentId": "faceguard.area1",
    "childId": "finish",
    "name": "Finish",
    "propId": "faceguard.area1.finish",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area1.finish"
  },
  {
    "parentId": "faceguard.area1",
    "childId": "color",
    "name": "Color",
    "propId": "faceguard.area1.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area1.color"
  },
  {
    "parentId": "faceguard",
    "childId": "area2",
    "name": "Faceguard Accent",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area2"
  },
  {
    "parentId": "faceguard.area2",
    "childId": "finish",
    "name": "Finish",
    "propId": "faceguard.area2.finish",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area2.finish"
  },
  {
    "parentId": "faceguard.area2",
    "childId": "color",
    "name": "Color",
    "propId": "faceguard.area2.color",
    "isWizardStep": false,
    "isWizardStepOptional": false,
    "shouldShowChildren": false,
    "id": "faceguard.area2.color"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<NavTreeItemRow[]>> = data;

export type NavTreeItem = typeof typed[number];

export const NAV_TREE_ITEM_INDEX_KEY = "id";
export type NavTreeItemIndexKey = "id";
export type NavTreeItemId = NavTreeItem["id"];

let i = 0;
export const NAV_TREE_ITEM_DICT = {
  "filter": typed[i++],
  "filter.productSelector": typed[i++],
  "filter.productSelector.productSelector.sport": typed[i++],
  "filter.productSelector.productSelector.model": typed[i++],
  "helmet": typed[i++],
  "helmet.size": typed[i++],
  "helmet.style": typed[i++],
  "helmet.area1": typed[i++],
  "helmet.area1.finish": typed[i++],
  "helmet.area1.color": typed[i++],
  "helmet.area2": typed[i++],
  "helmet.area2.finish": typed[i++],
  "helmet.area2.color": typed[i++],
  "helmet.area3": typed[i++],
  "helmet.area3.finish": typed[i++],
  "helmet.area3.color": typed[i++],
  "faceguard": typed[i++],
  "faceguard.model": typed[i++],
  "faceguard.side": typed[i++],
  "faceguard.area1": typed[i++],
  "faceguard.area1.finish": typed[i++],
  "faceguard.area1.color": typed[i++],
  "faceguard.area2": typed[i++],
  "faceguard.area2.finish": typed[i++],
  "faceguard.area2.color": typed[i++]
} as const;

export { typed as NAV_TREE };
