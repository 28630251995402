// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "model",
    "name": "Model",
    "propId": "helmet.model",
    "id": "product.model"
  },
  {
    "parentId": "product",
    "childId": "size",
    "name": "Size",
    "propId": "helmet.size",
    "id": "product.size"
  },
  {
    "parentId": "product",
    "childId": "sport",
    "name": "Sport",
    "propId": "filter.sport",
    "id": "product.sport"
  },
  {
    "childId": "helmet",
    "name": "Helmet",
    "id": "helmet"
  },
  {
    "parentId": "helmet",
    "childId": "style",
    "name": "Style",
    "propId": "helmet.style",
    "id": "helmet.style"
  },
  {
    "parentId": "helmet",
    "childId": "area1",
    "name": "Base",
    "id": "helmet.area1"
  },
  {
    "parentId": "helmet.area1",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area1.finish",
    "id": "helmet.area1.finish"
  },
  {
    "parentId": "helmet.area1",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area1.color",
    "id": "helmet.area1.color"
  },
  {
    "parentId": "helmet",
    "childId": "area2",
    "name": "Secondary",
    "id": "helmet.area2"
  },
  {
    "parentId": "helmet.area2",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area2.finish",
    "id": "helmet.area2.finish"
  },
  {
    "parentId": "helmet.area2",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area2.color",
    "id": "helmet.area2.color"
  },
  {
    "parentId": "helmet",
    "childId": "area3",
    "name": "Tertiary",
    "id": "helmet.area3"
  },
  {
    "parentId": "helmet.area3",
    "childId": "finish",
    "name": "Finish",
    "propId": "helmet.area3.finish",
    "id": "helmet.area3.finish"
  },
  {
    "parentId": "helmet.area3",
    "childId": "color",
    "name": "Color",
    "propId": "helmet.area3.color",
    "id": "helmet.area3.color"
  },
  {
    "childId": "faceguard",
    "name": "Faceguard",
    "id": "faceguard"
  },
  {
    "parentId": "faceguard",
    "childId": "model",
    "name": "Model",
    "propId": "faceguard.model",
    "id": "faceguard.model"
  },
  {
    "parentId": "faceguard",
    "childId": "flapSide",
    "name": "Faceguard Side",
    "propId": "faceguard.flapSide",
    "id": "faceguard.flapSide"
  },
  {
    "parentId": "faceguard",
    "childId": "area1",
    "name": "Base",
    "id": "faceguard.area1"
  },
  {
    "parentId": "faceguard.area1",
    "childId": "finish",
    "name": "Finish",
    "propId": "faceguard.area1.finish",
    "id": "faceguard.area1.finish"
  },
  {
    "parentId": "faceguard.area1",
    "childId": "color",
    "name": "Color",
    "propId": "faceguard.area1.color",
    "id": "faceguard.area1.color"
  },
  {
    "parentId": "faceguard",
    "childId": "area2",
    "name": "Accent",
    "id": "faceguard.area2"
  },
  {
    "parentId": "faceguard.area2",
    "childId": "finish",
    "name": "Finish",
    "propId": "faceguard.area2.finish",
    "id": "faceguard.area2.finish"
  },
  {
    "parentId": "faceguard.area2",
    "childId": "color",
    "name": "Color",
    "propId": "faceguard.area2.color",
    "id": "faceguard.area2.color"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.model": typed[i++],
  "product.size": typed[i++],
  "product.sport": typed[i++],
  "helmet": typed[i++],
  "helmet.style": typed[i++],
  "helmet.area1": typed[i++],
  "helmet.area1.finish": typed[i++],
  "helmet.area1.color": typed[i++],
  "helmet.area2": typed[i++],
  "helmet.area2.finish": typed[i++],
  "helmet.area2.color": typed[i++],
  "helmet.area3": typed[i++],
  "helmet.area3.finish": typed[i++],
  "helmet.area3.color": typed[i++],
  "faceguard": typed[i++],
  "faceguard.model": typed[i++],
  "faceguard.flapSide": typed[i++],
  "faceguard.area1": typed[i++],
  "faceguard.area1.finish": typed[i++],
  "faceguard.area1.color": typed[i++],
  "faceguard.area2": typed[i++],
  "faceguard.area2.finish": typed[i++],
  "faceguard.area2.color": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
