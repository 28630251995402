// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { PropDefRow } from '../typings';

const data = [
  {
    "id": "filter_sport",
    "name": "Sport",
    "tileType": "square",
    "tileImageProps": "icons-280/sport/${id}.png",
    "isOptional": true,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "filter.sport"
  },
  {
    "id": "helmet_model",
    "name": "Model",
    "tileType": "squareWithText",
    "tileImageProps": "icons/helmet/${props.icon}.png",
    "isOptional": true,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.model"
  },
  {
    "id": "helmet_size",
    "name": "Size",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.size"
  },
  {
    "id": "helmet_style",
    "name": "Style",
    "tileType": "squareWithText",
    "defaultValueId": "vents",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.style"
  },
  {
    "id": "helmet_area1_finish",
    "name": "Finish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area1.finish"
  },
  {
    "id": "helmet_area1_color",
    "name": "Color",
    "tileType": "square",
    "defaultValueId": "S",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area1.color"
  },
  {
    "id": "helmet_area2_finish",
    "name": "Finish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area2.finish"
  },
  {
    "id": "helmet_area2_color",
    "name": "Color",
    "tileType": "square",
    "defaultValueId": "W",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area2.color"
  },
  {
    "id": "helmet_area3_finish",
    "name": "Finish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area3.finish"
  },
  {
    "id": "helmet_area3_color",
    "name": "Color",
    "tileType": "square",
    "defaultValueId": "B",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "helmet.area3.color"
  },
  {
    "id": "faceguard_model",
    "name": "Faceguard Model",
    "tileType": "wideWithText",
    "isOptional": true,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.model"
  },
  {
    "id": "faceguard_area1_finish",
    "name": "Finish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.area1.finish"
  },
  {
    "id": "faceguard_area1_color",
    "name": "Color",
    "tileType": "square",
    "defaultValueId": "S",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.area1.color"
  },
  {
    "id": "faceguard_area2_finish",
    "name": "Finish",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.area2.finish"
  },
  {
    "id": "faceguard_area2_color",
    "name": "Color",
    "tileType": "square",
    "defaultValueId": "W",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.area2.color"
  },
  {
    "id": "faceguard_flapSide",
    "name": "Faceguard Side",
    "tileType": "squareWithText",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "faceguard.flapSide"
  },
  {
    "id": "legacy_sku",
    "name": "Legacy SKU",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "SKU"
  },
  {
    "id": "calc_price",
    "name": "Price",
    "tileType": "square",
    "isOptional": false,
    "viewNames": [
      "overview"
    ],
    "legacyPath": "price"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_sport": typed[i++],
  "helmet_model": typed[i++],
  "helmet_size": typed[i++],
  "helmet_style": typed[i++],
  "helmet_area1_finish": typed[i++],
  "helmet_area1_color": typed[i++],
  "helmet_area2_finish": typed[i++],
  "helmet_area2_color": typed[i++],
  "helmet_area3_finish": typed[i++],
  "helmet_area3_color": typed[i++],
  "faceguard_model": typed[i++],
  "faceguard_area1_finish": typed[i++],
  "faceguard_area1_color": typed[i++],
  "faceguard_area2_finish": typed[i++],
  "faceguard_area2_color": typed[i++],
  "faceguard_flapSide": typed[i++],
  "legacy_sku": typed[i++],
  "calc_price": typed[i++]
} as const;

export { typed as PROP_DEFS };
