// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FinishItemRow } from '../typings';

const data = [
  {
    "id": "clear",
    "name": "Clear",
    "props": {
      "clearCoatHex": "#ffffff"
    }
  },
  {
    "id": "matte",
    "name": "Matte",
    "props": {
      "clearCoatHex": "#000000"
    }
  },
  {
    "id": "metallic",
    "name": "Metallic",
    "props": {
      "clearCoatHex": "#ffffff"
    }
  },
  {
    "id": "volt",
    "name": "Volt",
    "props": {
      "clearCoatHex": "#ffffff"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FinishItemRow[]>> = data;

export type FinishItem = typeof typed[number];

export const FINISH_ITEM_INDEX_KEY = "id";
export type FinishItemIndexKey = "id";
export type FinishItemId = FinishItem["id"];

let i = 0;
export const FINISH_ITEM_DICT = {
  "clear": typed[i++],
  "matte": typed[i++],
  "metallic": typed[i++],
  "volt": typed[i++]
} as const;

export { typed as FINISHES };
