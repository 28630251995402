import { Texture, DynamicTexture } from '@babylonjs/core'
import * as _ from '@technically/lodash'
import Bluebird from 'bluebird'
import axios from 'axios'

import { Settings } from './RenderData'

import { updateSvgTexture } from './SvgTexture'

const domParser = new DOMParser()
const serializer = new XMLSerializer()

const getPBRMaterialOptional = (assetContainer, name) => {
  const material = _.find(
    assetContainer.materials,
    (material) => material.name === name,
  )

  if (material && material.getClassName() !== 'PBRMaterial') {
    throw new Error(
      `material is not PBRMaterial: ${name}, ${material.getClassName()}`,
    )
  }
  if (material) {
    if (name === 'outside' || name === 'faceguard' || name === 'inside') {
      material.enableSpecularAntiAliasing = false
      material.useRoughnessFromMetallicTextureGreen = true
    }
    if (name === 'outside' || name === 'faceguard') {
      material.clearCoat.isEnabled = true
      material.clearCoat.intensity = 0.8
    }
  }
  return material
}

const getPBRMaterial = (assetContainer, name) => {
  const material = getPBRMaterialOptional(assetContainer, name)
  if (!material) {
    throw new Error(
      `can not find material by name: ${name}, [${_.map(
        assetContainer.materials,
        (material) => material.name,
      ).join(', ')}]`,
    )
  }
  return material
}

const ensureTexture = (receiver, materialName, textureName) => {
  let texture = receiver[textureName]

  if (texture) {
    if (texture.getClassName() === 'Texture') {
      return texture
    }
    texture.dispose()
  }
  texture = new Texture()
  texture._deleteBuffer = true
  texture.name = `${materialName}_${textureName}`
  // receiver[textureName] = texture
  return texture
}

const ensureDynamicTexture = (
  receiver,
  materialName,
  textureName,
  settings = { size: undefined, hasAlpha: undefined },
) => {
  let texture = receiver[textureName]

  if (texture) {
    if (texture.getClassName() === 'DynamicTexture') {
      return texture
    }
    texture.dispose()
  }
  const name = `${materialName}_${textureName}`
  const size = settings.size || {
    width: 2048,
    height: 2048,
  }
  if (
    size.width > Settings.MAX_TEXTURE_SIZE ||
    size.height > Settings.MAX_TEXTURE_SIZE
  ) {
    const ratio = size.width / size.height
    size.width =
      ratio >= 1 ? Settings.MAX_TEXTURE_SIZE : Math.round(size.height * ratio)
    size.height =
      ratio <= 1 ? Settings.MAX_TEXTURE_SIZE : Math.round(size.width / ratio)
  }
  texture = new DynamicTexture(name, size)
  texture.hasAlpha = settings.hasAlpha || false
  texture.update()
  receiver[textureName] = texture
  return texture
}

export const disposeTextures = (state) => {
  const { textures } = state
  _.cloneDeepWith(textures, (maybeValue) => {
    if (!maybeValue) {
      return undefined
    }
    const value = maybeValue
    if (typeof value.dispose === 'function') {
      value.dispose()
      return null
    }
    return undefined
  })
}

export const initializeTextures = (assetContainer) => {
  const outside = getPBRMaterial(assetContainer, 'outside')
  const inside = getPBRMaterial(assetContainer, 'inside')
  const padding = getPBRMaterial(assetContainer, 'padding')
  const techLogoSide = getPBRMaterialOptional(assetContainer, 'techLogoSide')
  const techLogoBack = getPBRMaterialOptional(assetContainer, 'techLogoBack')
  const faceguard = getPBRMaterialOptional(assetContainer, 'faceguard')

  const carbonWeave = getPBRMaterialOptional(assetContainer, 'carbonWeave')
  if (carbonWeave) {
    carbonWeave.clearCoat.isEnabled = true
    carbonWeave.clearCoat.intensity = 0.8
  }

  const textures = {
    outside: {
      albedo: ensureDynamicTexture(outside, outside.name, 'albedoTexture', {
        size: {
          width: 4096,
          height: 4096,
        },
      }),
      clearCoat: ensureDynamicTexture(
        outside.clearCoat,
        `${outside.name}-clearCoat`,
        'texture',
      ),
      clearCoatNormal: ensureTexture(
        outside.clearCoat,
        `${outside.name}-clearCoat`,
        'bumpTexture',
      ),
      metallic: ensureDynamicTexture(outside, outside.name, 'metallicTexture'),
      normal: ensureDynamicTexture(outside, outside.name, 'bumpTexture'),
    },
    inside: {
      albedo: ensureDynamicTexture(inside, inside.name, 'albedoTexture'),
      metallic: ensureDynamicTexture(inside, inside.name, 'metallicTexture'),
    },
    padding: {
      albedo: ensureDynamicTexture(padding, padding.name, 'albedoTexture'),
    },
    techLogoSide: techLogoSide && {
      albedo: ensureDynamicTexture(
        techLogoSide,
        techLogoSide.name,
        'albedoTexture',
        {
          size: {
            width: 2048,
            height: 203,
          },
          hasAlpha: true,
        },
      ),
    },
    techLogoBack: techLogoBack && {
      albedo: ensureDynamicTexture(
        techLogoBack,
        techLogoBack.name,
        'albedoTexture',
        {
          size: {
            width: 2048,
            height: 203,
          },
          hasAlpha: true,
        },
      ),
    },
    faceguard: faceguard && {
      albedo: ensureDynamicTexture(faceguard, faceguard.name, 'albedoTexture'),
      clearCoat: ensureDynamicTexture(
        faceguard.clearCoat,
        `${faceguard.name}-clearCoat`,
        'texture',
      ),
      metallic: ensureDynamicTexture(
        faceguard,
        faceguard.name,
        'metallicTexture',
      ),
      normal: ensureDynamicTexture(faceguard, faceguard.name, 'bumpTexture'),
    },
  }

  return {
    textures,
    svgTextureLoaders: {},
    svgLoaders: {},
  }
}

const loadSvg = (svgLoaders, url) => {
  let promise = svgLoaders[url]
  if (!promise) {
    promise = axios.get(url).then((response) => response.data)
    svgLoaders[url] = promise
  }
  return promise
}

const resizeSvg = (texture, svgString) => {
  const { width, height } = texture.getSize()
  // console.log('resizeSvg', width, height, svgString)

  let result = svgString

  result = result.replace(/width=".+?"/, `width="${width}"`)
  result = result.replace(/height=".+?"/, `height="${height}"`)

  return result
}

const colorizeSvg = (texture, svgString, colors) => {
  let result = resizeSvg(texture, svgString)

  result = result.replace(/="red"/g, `="${colors.area1}"`)
  if (colors.area2) {
    result = result.replace(/="lime"/g, `="${colors.area2}"`)
  }
  if (colors.area3) {
    result = result.replace(/="blue"/g, `="${colors.area3}"`)
  }
  return result
}

const setAttr = (element, key, value) => {
  if (element.hasAttribute(key)) {
    element.removeAttribute(key)
  }
  element.setAttribute(key, value)
}

const applySvgPatterns = async (
  texture,
  svgString,
  textureSvgs,
  svgLoaders,
) => {
  const safeUrls = _.pickBy(textureSvgs, _.identity)
  const svgs = await Bluebird.props(
    _.mapValues(safeUrls, (url) => loadSvg(svgLoaders, url)),
  )
  if (_.every(safeUrls, (url) => url === safeUrls.area1)) {
    return resizeSvg(texture, svgs.area1)
  }

  const designDom = domParser.parseFromString(svgString, 'image/svg+xml')

  const designContent = designDom.firstElementChild
  if (!designContent) {
    throw new Error(`firstChild missing in designDom: ${svgString}`)
  }

  const { width, height } = texture.getSize()
  setAttr(designContent, 'width', width.toString(10))
  setAttr(designContent, 'height', height.toString(10))

  let defs = designDom.querySelector('defs')
  if (!defs) {
    const xmlns = 'http://www.w3.org/2000/svg'
    defs = designDom.createElementNS(xmlns, 'defs')
    designContent.insertBefore(defs, designContent.firstChild)
  }

  const fillValueMap = {}
  let overlay
  _.each(svgs, (svgTexture, areaId) => {
    const url = safeUrls[areaId]
    let fillValue = fillValueMap[url]
    if (!fillValue) {
      const textureDom = domParser.parseFromString(svgTexture, 'image/svg+xml')
      const rect = textureDom.querySelector('rect[width="4096"]')
      if (!rect) {
        return
      }
      fillValue = rect.getAttribute('fill')
      if (!fillValue) {
        return
      }

      const sourceDefs = textureDom.querySelector(`defs`)
      if (sourceDefs && defs) {
        while (sourceDefs.childNodes.length > 0) {
          defs.appendChild(sourceDefs.childNodes[0])
        }
        fillValueMap[url] = fillValue
      }

      if (!overlay) {
        overlay = textureDom.querySelector(
          'image[style="mix-blend-mode:overlay"]',
        )
        if (overlay) {
          designContent.appendChild(overlay)
        }
      }
    }

    const areas = designDom.querySelectorAll(`[data-name=${areaId}]`)
    _.each(areas, (area) => {
      setAttr(area, 'fill', fillValue || 'unknown')
    })
  })

  const result = serializer.serializeToString(designDom)
  return result
}

export const updateTextures = (state, data) => {
  // OUTSIDE
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.outside.albedo,
    data.textures.outside.albedo.svg,
    data.textures.outside.albedo,
    (texture, svgString) =>
      colorizeSvg(texture, svgString, data.textures.outside.albedo.colors),
  )
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.outside.clearCoat,
    data.textures.outside.clearCoat.svg,
    data.textures.outside.clearCoat,
    (texture, svgString) =>
      colorizeSvg(texture, svgString, data.textures.outside.clearCoat.colors),
  )

  if (
    state.textures.outside.clearCoatNormal.url !==
    data.textures.outside.clearCoatNormal
  ) {
    state.textures.outside.clearCoatNormal.updateURL(
      data.textures.outside.clearCoatNormal,
    )
  }

  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.outside.metallic,
    data.textures.outside.metallic.svg,
    data.textures.outside.metallic,
    (texture, svgString) =>
      applySvgPatterns(
        texture,
        svgString,
        data.textures.outside.metallic.textureSvgs,
        state.svgLoaders,
      ),
  )
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.outside.normal,
    data.textures.outside.normal.svg,
    data.textures.outside.normal,
    (texture, svgString) =>
      applySvgPatterns(
        texture,
        svgString,
        data.textures.outside.normal.textureSvgs,
        state.svgLoaders,
      ),
  )

  // INSIDE
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.inside.albedo,
    data.textures.inside.albedo.svg,
    data.textures.inside.albedo,
    (texture, svgString) =>
      colorizeSvg(texture, svgString, data.textures.inside.albedo.colors),
  )
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.inside.metallic,
    data.textures.inside.metallic.textureSvg,
    data.textures.inside.metallic,
    (texture, svgString) => resizeSvg(texture, svgString),
  )

  // PADDING
  updateSvgTexture(
    state.svgTextureLoaders,
    state.textures.padding.albedo,
    data.textures.padding.albedo.svg,
    data.textures.padding.albedo,
    (texture, svgString) => resizeSvg(texture, svgString),
  )

  // TECH LOGO
  if (state.textures.techLogoSide && data.textures.techLogoSide) {
    updateSvgTexture(
      state.svgTextureLoaders,
      state.textures.techLogoSide.albedo,
      data.textures.techLogoSide.albedo.svg,
      data.textures.techLogoSide.albedo,
      (texture, svgString) =>
        colorizeSvg(
          texture,
          svgString,
          data.textures.techLogoSide.albedo.colors,
        ),
    )
  }
  if (state.textures.techLogoBack && data.textures.techLogoBack) {
    updateSvgTexture(
      state.svgTextureLoaders,
      state.textures.techLogoBack.albedo,
      data.textures.techLogoBack.albedo.svg,
      data.textures.techLogoBack.albedo,
      (texture, svgString) =>
        colorizeSvg(
          texture,
          svgString,
          data.textures.techLogoBack.albedo.colors,
        ),
    )
  }

  // FACEGUARD
  if (state.textures.faceguard && data.textures.faceguard) {
    const faceguardState = state.textures.faceguard
    const faceguardData = data.textures.faceguard
    updateSvgTexture(
      state.svgTextureLoaders,
      faceguardState.albedo,
      faceguardData.albedo.svg,
      faceguardData.albedo,
      (texture, svgString) =>
        colorizeSvg(
          faceguardState.albedo,
          svgString,
          faceguardData.albedo.colors,
        ),
    )
    // console.log('FACEGUARD')

    // console.log(faceguardState.clearCoat === state.textures.outside.clearCoat)
    // console.log(
    //   faceguardData.clearCoat.svg === data.textures.outside.clearCoat.svg,
    // )
    // console.log(faceguardData.clearCoat === data.textures.outside.clearCoat)

    // console.log(faceguardState.clearCoat, state.textures.outside.clearCoat)
    // console.log(
    //   faceguardData.clearCoat.svg,
    //   data.textures.outside.clearCoat.svg,
    // )
    // console.log(faceguardData.clearCoat, data.textures.outside.clearCoat)

    updateSvgTexture(
      state.svgTextureLoaders,
      faceguardState.clearCoat,
      faceguardData.clearCoat.svg,
      faceguardData.clearCoat,
      (texture, svgString) =>
        colorizeSvg(
          faceguardState.clearCoat,
          svgString,
          faceguardData.clearCoat.colors,
        ),
    )
    updateSvgTexture(
      state.svgTextureLoaders,
      faceguardState.metallic,
      faceguardData.metallic.svg,
      faceguardData.metallic,
      (texture, svgString) =>
        applySvgPatterns(
          texture,
          svgString,
          faceguardData.metallic.textureSvgs,
          state.svgLoaders,
        ),
    )
    updateSvgTexture(
      state.svgTextureLoaders,
      faceguardState.normal,
      faceguardData.normal.svg,
      faceguardData.normal,
      (texture, svgString) =>
        applySvgPatterns(
          texture,
          svgString,
          faceguardData.normal.textureSvgs,
          state.svgLoaders,
        ),
    )
  }
}

export const areTexturesReady = (state) =>
  _.every(
    state.svgTextureLoaders,
    (loadState) => loadState.status === 'empty' || loadState.status === 'ready',
  ) && state.textures.outside.clearCoatNormal.isReady()
