// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ModelRow } from '../typings';

const data = [
  {
    "id": "MachCarbon",
    "name": "Mach Carbon",
    "description": "A sleek, unique ABS/Carbon Fiber hybrid design with optimized ventilation and featuring IMPAX padding. Recommended for collegiate/elite players it is the only double-eared baseball helmet on the market SEI certified to meet the NOCSAE® standard up to 95 miles-per-hour baseball pitch speeds.",
    "props": {
      "icon": "MCHCAR",
      "sku": "MCHCAR-CONFIG",
      "price": 209.99,
      "assetIds": {
        "base": "Mach",
        "paddingSuffix": "Carbon",
        "frontPaddingSuffix": "Default"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  },
  {
    "id": "Mach",
    "name": "Mach",
    "description": "Inspired by the authentic MLB® helmet. Available in senior and junior sizes, features IMPAX padding and is compatible with the MEXT extension. Recommended for collegiate, travel ball and high school athletes, and is SEI certified to meet the NOCSAE® standard.",
    "props": {
      "icon": "MACH",
      "sku": "MACH-CONFIG",
      "price": 79.99,
      "assetIds": {
        "base": "Mach",
        "paddingSuffix": "Default",
        "frontPaddingSuffix": "Default"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  },
  {
    "id": "MachAlpha",
    "name": "Mach Alpha",
    "description": "Provides a full, custom fit with S-XL sizing and a wrapped headband and jaw pads for additional comfort. Compatible with the MEXT extension. This series is recommended for collegiate, travel ball and high school athletes, and is SEI certified to meet the NOCSAE® standard.",
    "props": {
      "icon": "MCHA",
      "sku": "MCHA-CONFIG",
      "price": 79.99,
      "assetIds": {
        "base": "Mach",
        "paddingSuffix": "Alpha",
        "frontPaddingSuffix": "Alternate"
      }
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": false
      }
    }
  },
  {
    "id": "R16",
    "name": "Velo",
    "description": "Constructed with 16 vents for optimal air flow and circulation allowing its wearer to stay cool and dry. Available in both senior and junior sizes, is recommended for high school and youth athletes, and is SEI certified to meet the NOCSAE® standard.",
    "props": {
      "icon": "R16",
      "sku": "R16-CONFIG",
      "price": 69.99,
      "assetIds": {
        "base": "R16"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  },
  {
    "id": "RCFH",
    "name": "Coolflo",
    "description": "Recommended for youth athletes, the design features state-of-the-art venting system and is conveniently offered in a one-sized-fits-most configuration. Available in traditional and throwback designs only and is SEI certified to meet the NOCSAE® standard.",
    "props": {
      "icon": "RCFH",
      "sku": "RCFH-CONFIG",
      "price": 54.99,
      "assetIds": {
        "base": "RCFH"
      }
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": true
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ModelRow[]>> = data;

export type Model = typeof typed[number];

export const MODEL_INDEX_KEY = "id";
export type ModelIndexKey = "id";
export type ModelId = Model["id"];

let i = 0;
export const MODEL_DICT = {
  "MachCarbon": typed[i++],
  "Mach": typed[i++],
  "MachAlpha": typed[i++],
  "R16": typed[i++],
  "RCFH": typed[i++]
} as const;

export { typed as MODELS };
