import React from 'react'
import * as _ from '@technically/lodash'
import { createRoot } from 'react-dom/client'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import * as controlTreeDebugUtils from '~p/client/control-tree/debugUtils'

import Root from './components/Root'
import store from './store'
import controlTree from './controlTree'

// For debugging.
window.store = store

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
_.forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

viewportUnitsBuggyfill.init()

createRoot(document.getElementById('root')).render(<Root store={store} />)
