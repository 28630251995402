/* eslint-disable no-param-reassign */

import Bluebird from 'bluebird'
import axios from 'axios'
import * as _ from '@technically/lodash'

export const loadSvg = (url) => axios.get(url).then((response) => response.data)

export const drawSvg = async (svgString, texture) => {
  // console.log(svgString.substr(0, 256))

  // https://stackoverflow.com/a/30273776/1398700
  svgString = svgString.replace(' xlink=', ' xmlns:xlink=')
  svgString = svgString.replace(
    /xmlns:ns.+?="http:\/\/www.w3.org\/1999\/xlink"/gi,
    'xmlns:xlink="http://www.w3.org/1999/xlink"',
  )
  svgString = svgString.replace(/ ns\d+:.+?=".*?"/gi, '')
  svgString = svgString.replace(/ xmlns:ns.+?=".*?"/gi, '')
  svgString = svgString.replace(/ svgjs:data=".*?"/gi, '') // text

  const dataUrl = `data:image/svg+xml;base64,${window.btoa(
    window.unescape(window.encodeURIComponent(svgString)),
  )}`

  const image = new Image()
  image.crossOrigin = 'Anonymous'

  return new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      image.src = ''
    })

    image.onload = () => {
      const ctx = texture.getContext()
      if (
        image.width !== ctx.canvas.width ||
        image.height !== ctx.canvas.height
      ) {
        console.debug(
          'drawImage size mismatch',
          texture.name,
          'image:',
          image.width,
          image.height,
          'canvas:',
          ctx.canvas.width,
          ctx.canvas.height,
        )
        ctx.drawImage(image, 0, 0, ctx.canvas.width, ctx.canvas.height)
      } else {
        ctx.drawImage(image, 0, 0)
      }

      texture.update(false)
      image.src = ''
      resolve()
    }
    image.onerror = (error) => {
      reject(
        new Error(
          `Can not read resource as dataURL: ${dataUrl}, error: ${error.message}`,
        ),
      )
    }
    image.onabort = () => {
      reject(new Error(`Aborted readAsDataURL: ${dataUrl}`))
    }

    image.src = dataUrl
  })
}

const getSvgTextureLoader = (loaderCache, texture) => {
  let state = loaderCache[texture.name]
  if (!state) {
    state = {
      texture,
      status: 'empty',
    }
    loaderCache[texture.name] = state
  }
  return state
}

export const updateSvgTexture = async (
  loaderCache,
  texture,
  url,
  data,
  svgLogic,
) => {
  let state = getSvgTextureLoader(loaderCache, texture)
  const updateState = (state) => {
    loaderCache[texture.name] = state
    return state
  }
  const isInvalidateStateAfterAwait = (state) =>
    loaderCache[texture.name] !== state

  //  RESET DATA
  // console.log('state', state)
  if (!_.isEqual(state.data, data)) {
    if (
      state.status === 'ready' ||
      state.status === 'rasterizing' ||
      state.status === 'replace'
    ) {
      if (state.rasterBluebird && state.rasterBluebird.cancel) {
        state.rasterBluebird.cancel()
      }

      state = updateState({
        texture,
        data,
        status: 'loaded',
        url: state.url,
        svgString: state.svgString,
      })
    }
  }

  //  RESET URL
  // console.log('RESET URL state', state)
  if (state.url !== url) {
    if (state.rasterBluebird && state.rasterBluebird.cancel) {
      state.rasterBluebird.cancel()
    }
    state = updateState({
      status: 'empty',
      texture,
    })
  }

  // LOAD SVG
  // console.log('state', state)
  if (!url) {
    return
  }
  if (state.status === 'empty') {
    if (!url) {
      return
    }
    state = updateState({
      status: 'loading',
      texture,
      data,
      url,
      svgPromise: loadSvg(url),
    })
  }

  // COMPLETE SVG LOADING
  // console.log('state', state)
  if (state.status === 'loading') {
    const svgString = await state.svgPromise
    if (isInvalidateStateAfterAwait(state)) {
      return
    }
    state = updateState({
      status: 'loaded',
      texture,
      data,
      url,
      svgString,
    })
  }

  // REPLACE
  if (state.status === 'loaded' && svgLogic) {
    const svgStringResult = svgLogic(texture, state.svgString)
    if (typeof svgStringResult === 'string') {
      const svgStringReplaced = svgStringResult
      state = updateState({
        status: 'replaced',
        texture,
        data,
        url,
        svgString: state.svgString,
        svgStringReplaced,
      })
    } else {
      state = updateState({
        status: 'replacing',
        texture,
        data,
        url,
        svgString: state.svgString,
        replacePromise: svgStringResult,
      })
    }
  }

  // COMPLETE REPLACE
  if (state.status === 'replacing') {
    const svgStringReplaced = await state.replacePromise
    if (isInvalidateStateAfterAwait(state)) {
      return
    }
    state = updateState({
      status: 'replaced',
      texture,
      data,
      url,
      svgString: state.svgString,
      svgStringReplaced,
    })
  }

  // RASTERIZE
  // console.log('state', state)
  if (state.status === 'loaded' || state.status === 'replaced') {
    state = updateState({
      status: 'rasterizing',
      texture,
      data,
      url,
      svgString: state.svgString,
      svgStringReplaced: state.svgStringReplaced,
      rasterBluebird: drawSvg(
        state.svgStringReplaced || state.svgString,
        texture,
      ),
    })
  }

  // COMPLETE
  // console.log('state', state)
  if (state.status === 'rasterizing') {
    await state.rasterBluebird
    if (isInvalidateStateAfterAwait(state)) {
      return
    }
    state = updateState({
      status: 'ready',
      texture,
      data,
      url,
      svgString: state.svgString,
      svgStringReplaced: state.svgStringReplaced,
    })
  }

  // console.log('state', state)
}
